import React from "react"
import Layout from "../../components/layouts/Layout.js"
import { graphql, useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../../components/GatsbyLink"
import Banner from "../../components/Banner.js"
import * as styles from "../../stylesheets/pages/internal-links.module.scss"

const InternalLinks = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicInternalLinks {
        data {
          links {
            richText
          }
        }
      }
    }
  `).prismicInternalLinks.data

  return (
    <Layout>
      <div className={styles.container}>
        <RichText
          render={data.links.richText}
          serializeHyperlink={GatsbyLink}
        />
      </div>
    </Layout>
  )
}

export default InternalLinks
