import React from "react"
import * as styles from "../stylesheets/components/banner.module.scss"
import bannerImage from "../images/new_banner_3.jpeg"

const Banner = ({
  image = bannerImage,
  alt = "TechCollective",
  header = "TechCollective",
  subHeader = "a worker-owned managed service provider",
}) => {
  return (
    <div className={styles.banner}>
      <div className={styles.mainTitle}>
        <h1>{header}</h1>
        <h3>{subHeader}</h3>
      </div>
      <img src={image} alt={alt} className={styles.bannerImage} />
    </div>
  )
}

export default Banner
